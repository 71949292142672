#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

img.cover {
    height: 13vw;
    margin: 0.5vw;
    min-height: 200px;
}

#game-tooltip img.cover {
    margin: 0;
}

.tooltip-content {
    background-color: black;
    padding: 5px;
    margin: 0;
    z-index: 99;
}

.tooltip-cursor {
    cursor: pointer;
}

div.legend {
    display: grid;
    grid-template-columns: 1fr 5fr;
}

.horizontally-scrollable {
    overflow-x: auto;
    overflow-y: hidden;
}

.data-list-panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
}

.data-list-panel-content {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 0 !important;
}

.gallery-panel-content {
    background: linear-gradient(#efefef 0%, #fff 25%) !important;
}

.stats-panel-content {
    padding: 1rem !important;
    background: linear-gradient(#efefef 0%, #fff 25%) !important;
}

.ranking-edit-panel-content {
    padding: 1rem !important;
    background: linear-gradient(#efefef 0%, #fff 25%) !important;
}

.admin-panel-content {
    background: linear-gradient(#efefef 0%, #fff 25%) !important;
}

.tierlist-panel-content {
    padding: 1rem !important;
    background: linear-gradient(#efefef 0%, #fff 25%) !important;
}

.form-second-column {
    grid-column: 2;
}

.form-second-column-span {
    grid-column: span 2;
}

.cover-shadow {
    border: 1px solid white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5), 0 -2px 5px rgba(0, 0, 0, 0.5);
}

.go-to-history-link {
    color: inherit !important;
}

/* theme style customizations */

.p-datatable .p-datatable-thead > tr > th {
    background-color: #efefef !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.4rem 0.2rem !important;
}

.p-datatable .p-datatable-tbody > tr.soldGameRow {
    color: gray;
    text-decoration: line-through;
}

div.filter-toggle.p-togglebutton.p-button.p-highlight {
    background-color: var(--primary-color);
}

div.p-multiselect.icon-only .p-multiselect-label-container {
    width: 0;
}

div.p-panel.data-list-panel > div.p-toggleable-content {
    flex-grow: 1;
    overflow: auto;
}

.pi-rotate-90 {
    rotate: 90deg;
}

.tierlist-row {
    border: 1px solid #acacac;
}

.tierlist-row:nth-child(odd) {
    background-color: rgba(239, 239, 239, 0.4);
}

.tierlist-row:nth-child(even) {
    background-color: rgba(239, 239, 239, 0.1);
}

.tierlist-header {
    text-align: center;
    border: 1px solid #acacac;
}

.tierlist-action-button {
    font-size: 0.5rem !important;
    width: 1.5rem !important;
    padding: 0.1rem !important;
}